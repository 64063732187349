<template>
  <b-container class="main-content-container px-4" fluid>
    <b-row class="page-header py-4 d-flex" no-gutters>
      <b-col class="text-center text-sm-left mb-4 mb-sm-0">
        <h3 class="page-title">{{ $t('support.title_card') }}</h3>
        <span class="text-uppercase page-subtitle">{{ $t('support.title') }}</span>
      </b-col>
      <b-col class="mb-4 mb-sm-0 d-flex justify-content-end" v-if="$userCan(['administrator'])">
        <b-button variant="primary" v-b-modal.modal-1>UPCOMING CLOSED DATES</b-button>
      </b-col>
    </b-row>
    <template v-if="$userCan(['distributor','dealer']) && haveSubUsers">
      <b-tabs class="custom-tab">
        <b-tab :title="tabTitle" lazy @click="changeMode('client')">
          <Staff mode="client" :title="tabTitle"/>
        </b-tab>
        <b-tab title="DEALERS" lazy @click="changeMode('admin')">
          <Staff mode="admin" title="DEALERS"/>
        </b-tab>
      </b-tabs>
    </template>
    <Staff v-else :mode="$userCan(['administrator','calibrator','support'])? 'admin' : 'client'"/>

    <b-modal id="modal-1" size="lg" title="UPCOMING CLOSED DATES" hide-footer>
      <b-row>
        <b-col class="col-lg-6 col-md-12 col-sm-12">
          <b-calendar value-as-date
                      hide-header
                      block
                      :min="currentDate"
                      @selected="setDate"
                      :date-disabled-fn="datesDisabled"
                      locale="en"
                      calendarLocale="en"></b-calendar>
          <b-row class="mt-4">
            <b-col class="d-flex justify-content-around">
              <b-button variant="danger" @click="sendEmailNotification">SEND EMAIL NOTIFICATION</b-button>
              <b-button variant="primary" @click="addDaySupport">ADD DAY TO CALENDAR</b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="col-lg-6 col-md-12 col-sm-12 table-responsive">
          <table class="table table-sm table-hover table-bordered">
            <thead>
            <tr>
              <td>DATE</td>
              <td>OPTIONS</td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in calendarItems" :key="index">
              <td>{{ item.date }}</td>
              <td>
                <b-button size="sm" variant="danger" @click="deleteCalendarDay(item.id)">Delete</b-button>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>

    </b-modal>

  </b-container>

</template>

<script>
import {get, post, put} from '@/services/api';
import Staff from '@/views/dashboard/support/staff/index.vue';
import {mapState} from 'vuex';
import * as moment from "moment";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: 'Inbox',
  mixins: [show_alert_mixin],
  components: {
    Staff,
  },
  data: () => ({
    currentDate: moment(new Date).format('Y-MM-DD'),
    supportUsers: [],
    routerName: null,
    tabTitle: '',
    calendarValue: null,
    fields: [
      {key: 'date', label: 'Date', sortable: true, sortDirection: 'desc'},
      {key: 'actions', label: 'Actions'}
    ],
    calendarItems: [],
  }),
  watch: {
    $route() {
      let id = this.$route.params.id;
      this.routerName = this.$route.name;
      if (id) {
        this.loadByParam(id);
        this.autoSelect = true;
      }
    },
    tabTitle() {
      return (this.$store.state.buySale.information.haveParent == true) ? 'DISTRIBUTOR' : 'TVS'
    }
  },
  mounted() {
    this.getAllEvents()
    let id = this.$route.params.id;
    this.routerName = this.$route.name;
    if (id) {
      this.loadByParam(id);
      this.autoSelect = true;
    }
    this.$store.dispatch('support/loadCategoryTypes');
    this.$store.dispatch('support/loadStatusTypes');
    this.getUsersSupport();
    this.tabTitle = (this.$store.state.buySale.information.haveParent == true) ? 'DISTRIBUTOR' : 'TVS';
  },
  methods: {
    setDate(e) {
      this.calendarValue = e;
    },
    loadList(id, clear = true) {
      this.$refs.list.load(id, this.routerName);
      if (clear) {
        this.$refs.chat.clear();
        this.$refs.cats.setTicketToUpdate(null);
      }
    },
    loadChat(id) {
      this.$refs.chat.loadSupport(id);
      this.$refs.cats.setTicketToUpdate(id);
    },
    loadByParam(support_id) {
      this.loadChat(support_id);
      this.$refs.chat.$once('categoryId', async (e) => {
        this.loadList(e, false);
        this.$refs.cats.setSelected(e);
        this.$refs.list.$once('loaded', () => {
          this.$refs.list.setSelected(support_id);
        });
      });
    },
    getUsersSupport() {
      get('user/support,administrator,calibrator', null, true)
        .then(response => {
          this.supportUsers = response.data;
        });
    },
    setSupport(support) {
      this.$refs.cats.setTicketToUpdate(support);
    },
    updatedTicket(support) {
      let key = this.$refs.list.list.findIndex(el => el.id === support.id);
      let newSupport = Object.assign(this.$refs.list.list[key], support);
      this.$set(this.$refs.list.list, key, newSupport);
      this.$refs.chat.support = support;
    },
    changeMode(mode) {
      this.$eventBus.$emit('closeModalTicket');
    },
    async addDaySupport() {
      if (this.calendarValue !== null) {
        this.$swal({
          showConfirmButton: true,
          showCancelButton: true,
          title: 'Are you sure?',
          confirmButtonColor: "#d14343",
          cancelButtonColor: "#000000",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then(async res => {
          if (res.isConfirmed) {
            await post('calendars/support-add-day-close', {date: this.calendarValue}, true)
            this.calendarValue = null
            this.getAllEvents().then()
            this.showAlertNotification("Success")
          }
        });
      }
    },
    async getAllEvents() {
      this.calendarItems = []
      const {data} = await get('calendars/support-get-day-close')
      this.calendarItems = data
    },
    async deleteCalendarDay(row) {
      await post('calendars/support-delete-day-close', {id: row}, true)
      this.getAllEvents().then()
    },
    datesDisabled(ymd, date) {
      const weekday = date.getDay()
      return weekday === 0 || weekday === 6
    },
    sendEmailNotification() {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        icon: 'question',
        title: 'Are you sure?',
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (value) => {
        if (value.isConfirmed) {
          await post("calendars/send-notification", null, true)
          this.showAlertNotification("Success")
        }
      })
    },
  },
  computed: mapState('buySale', {
    haveSubUsers: state => state.information.haveChildren,
  }),
};
</script>

<style scoped lang="scss">
.content-inbox {
  height: calc(100vh - 10vw);
}
</style>
