import Vue from 'vue';
import {get, post} from '@/services/api';
import Sessions from '@/views/dashboard/support/components/Sessions';
import {mapState} from 'vuex';
import {serialize} from '@/utils';
import {TICKET_STATUS} from "@/constants";
import {encrypt} from "@/helpers/encrypt";

export default Vue.extend({
  name: 'Staff',
  props: {
    mode: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
  },
  components: {
    aoSessions: Sessions,
  },
  data: () => ({
    maxNumber: 0,
    amountNew: 0,
    amountInProcess: 0,
    amountClosed: 0,
    filter: {
      username: '',
      support_ticket_cat_id: null,
      support_ticket_status_id: null,
      staff_user_id: null,
      question_dep: null,
      view_all: false
    },
    pagination: {
      perPage: 20,
      currentPage: 1,
      rows: 0
    },
    arrayData: [],
    fields: ['id',
      {
        key: 'ownerId',
        label: 'Owner',
        thClass: 'd-none',
        tdClass: 'd-none',
      },
      {key: 'extraInfo', label: 'Unread'},
      {
        key: 'staffUser.name',
        label: 'Support'
      }, 'title', {
        key: 'clientUser.fullName',
        label: 'Customer'
      }, {
        key: 'supportTicketCat.name',
        label: 'Subject'
      }, {
        key: 'supportTicketStatus.name',
        label: 'Status'
      }, {
        key: 'createdAt',
        label: 'Date'
      },
      'actions'
    ],
    showGraphic: false,
    supportUsers: [],
    tabTitle: '',
    timeOut: null,
  }),
  computed: {
    ...mapState('support', {
      supportCats: state => state.ticketCategoryTypes,
      supportStatus: state => state.ticketStatusTypes,
      filter: state => state.filter
    }),
  },
  methods: {
    emit() {
      this.$emit('dataChange', this.$refs.table.data);
      let tempData = this.$refs.table.data;
      this.amountNew = this.getAmount('New', tempData);
      this.amountInProcess = this.getAmount('In process', tempData);
      this.amountClosed = this.getAmount('Closed', tempData);
    },
    getAmount(type, data) {
      let val = data.filter(x => x.supportTicketStatus.name == type).length;
      if (val > this.maxNumber) {
        this.maxNumber = val;
      }
      return val;
    },
    getStatusClass(status) {
      const map = {
        New: 'primary',
        'In process': 'warning',
        Closed: 'danger',
      };
      return `text-${map[status]}`;
    },
    getUsersSupport() {
      get('user/support,administrator,calibrator', null, true)
        .then(response => {
          this.supportUsers = response.data;
        });
    },
    updateSupport(e, id, key) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Are you sure?',
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(({isConfirmed}) => {
        if (isConfirmed) {
          let partialData = {};
          partialData[key] = e;
          post(`support/update/${id}`, partialData)
            .then(({data}) => {
              this.support = data;
              this.load()
            });
        }
      })
    },
    load() {
      this.getAndSetQueryParams();
      const params = serialize({
        ...this.filter,
        perPage: this.pagination.perPage,
        page: this.pagination.currentPage,
        mode: this.mode
      }, '?');
      get('support', params, true)
        .then(({data}) => {
          this.arrayData = data.data;
          this.pagination.rows = data.count;
          this.$refs.paginator.currentPage = this.pagination.currentPage
        });
      this.afterLoad();
    },
    getAndSetQueryParams() {
      if (this.$route.query.page) {
        this.pagination.currentPage = Number(this.$route.query.page)
        this.pagination.perPage = Number(this.$route.query.perPage)
      }
    },
    paginate(page) {
      this.$router.push({
        name: 'support', query: {
          page: page,
          perPage: this.pagination.perPage,
          ...this.filter
        }
      }).catch(() => false)
    },
    goView(item) {
      if (item.orderId) {
        const cryp = encrypt(JSON.stringify({id: item.orderId}))
        this.$router.push({
          name: 'order-detail',
          params: {id: cryp},
          query: {'open-chat': 1}
        });
      } else {
        const cryp = encrypt(JSON.stringify({id: item.id}))
        this.$router.push({
          name: 'support-detail',
          params: {id: cryp}
        });
      }
    },
    createTicket() {
      this.$eventBus.$emit('openTicket', {
        mode: this.mode, // admin - client
        redirect: true
      });
    },
    getColor(status) {
      if (status !== undefined) {
        const obj = TICKET_STATUS.filter(x => x.value.toString() === status.toString())
        return (obj.length) ? obj[0].color : 'black'
      } else {
        return 'black'
      }
    },
    getUnread(item) {
      let extra = JSON.parse(item.extraInfo)
      let field = 'unread';
      if (item.clientUserId == this.$store.getters.getUserId) {
        field = 'client_unread'
      }
      return Boolean(extra[field])
    },
    afterLoad() {
      if (this.filter.view_all) {
        this.fields[1].tdClass = '';
        this.fields[1].thClass = '';
      } else {
        this.fields[1].tdClass = 'd-none';
        this.fields[1].thClass = 'd-none';
      }
    },
  },
  async mounted() {
    this.load();
    this.tabTitle = (this.$store.state.buySale.information.haveParent == true) ? 'DISTRIBUTOR' : 'TVS';
    await this.$store.dispatch('support/loadStatusTypes');
    if (this.$userCan(['administrator', 'support', 'calibrator'])) {
      this.getUsersSupport();
    }
    this.sockets.subscribe('supportUpdateList', () => {
      this.load();
    });
  },
  watch: {
    $route() {
      this.load()
    },
    filter: {
      handler: function (old, new_val) {
        this.paginate(1)
      },
      deep: true,
      immediate: false
    },
    mode: function () {
      this.load();
    },
    tabTitle() {
      return (this.$store.state.buySale.information.haveParent === true) ? 'DISTRIBUTOR' : 'TVS'
    }
  },
  destroyed() {
    this.sockets.unsubscribe('supportUpdateList');
  }
});
