<template>
  <b-card class="m-4">
    <!-- Card Header -->
    <b-card-body class="pt-0">
      <div ref="history"></div>
      <canvas
        ref="history_canvas"
        class="analytics-overview-sessions"
        height="120"
        style="max-width: 100% !important;"
      ></canvas>
    </b-card-body>
    <b-card-body class="pt-0">
      <div ref="bars"></div>
      <canvas
        ref="bars_canvas"
        class="analytics-overview-sessions"
        height="120"
        style="max-width: 100% !important;"
      ></canvas>
    </b-card-body>
  </b-card>
</template>

<script>
import colors from '../../../../utils/colors';
import Chart from '../../../../utils/chart';
import {get} from "@/services/api";

const defaultChartData = {
  labels: [
    '09:00 PM',
    '10:00 PM',
    '11:00 PM',
    '12:00 PM',
    '13:00 PM',
    '14:00 PM',
    '15:00 PM',
    '16:00 PM',
    '17:00 PM',
  ],
  datasets: [
    {
      label: 'Today',
      fill: 'start',
      data: [5, 5, 10, 30, 10, 42, 5, 15, 5],
      backgroundColor: colors.primary.toRGBA(0.1),
      borderColor: colors.primary.toRGBA(1),
      pointBackgroundColor: colors.white.toHex(),
      pointHoverBackgroundColor: colors.primary.toRGBA(1),
      borderWidth: 1.5,
    },
  ],
};

export default {
  name: 'sessions',
  props: {
    title: {
      type: String,
      default: 'Sessions',
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        legend: false,
        elements: {
          line: {
            tension: 0.38,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMax: 45,
              },
            },
          ],
        },
        tooltips: {
          enabled: false,
          mode: 'index',
          position: 'nearest',
        },
      },
      dateRange: {
        from: null,
        to: null,
      },
      chartHistoryData: null,
      chatBarsData: null
    };
  },
  methods: {
    setHistory() {
      const AnalyticsOverviewChart = new Chart(this.$refs.history_canvas, {
        type: 'line',
        data: this.chartHistoryData,
        options: this.chartOptions,
      });
    },
    setBars() {
      const AnalyticsOverviewChart = new Chart(this.$refs.bars_canvas, {
        type: 'bar',
        data: this.chatBarsData,
        options: this.chartOptions,
      });
    }
  },
  async mounted() {
    await get('support/graphics-history', null, false)
      .then(response => {
        this.chartHistoryData = response.data;
        this.chartHistoryData.datasets = this.chartHistoryData.datasets.map(el => {
          return {
            ...el,
            backgroundColor: colors.primary.toRGBA(0.5),
            borderColor: colors.primary.toRGBA(1),
            pointBackgroundColor: colors.primary.toHex(),
            pointHoverBackgroundColor: colors.primary.toRGBA(1),
            borderWidth: 1.5,
          }
        })
        this.setHistory();
      })
    await get('support/graphics-bars', null, false)
      .then(response => {
        this.chatBarsData = response.data;
        this.chatBarsData.datasets = this.chatBarsData.datasets.map(el => {
          return {
            ...el,
            backgroundColor: colors.primary.toRGBA(0.5),
            borderColor: colors.primary.toRGBA(1),
            pointBackgroundColor: colors.gray.toHex(),
            pointHoverBackgroundColor: colors.primary.toRGBA(1),
            borderWidth: 1.5,
          }
        })
        this.setBars();
      })
  },
};
</script>

